<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('menu.setting.categories')" :swiper_tabs.sync="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "AdvancedSettings",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'categories_horse',

                swiper_tabs: [{
                        id: 'categories_horse',
						label: 'menu.setting.categories_horse',
						active: false,
                        href : 'categoriesHorse'
					},
					{
                        id: 'categories_note',
						label: 'menu.setting.categories_note',
						active: false,
                        href : 'categoriesNote'
					}
				]
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
